<template>
  <v-card style="margin-bottom:1em" class="OrcamentoListFiltros">
    <v-card-title>
      <div class="title">{{ $tc('title.filtro', 2) }}</div>
      <v-spacer></v-spacer>
      <v-icon v-show="filtrosAbertos"
        @click="fixaFiltros"
        :class="filtrosFixosClass"
        style="margin-right: 10px">
        fa-thumbtack
      </v-icon>
      <icon-filtro @click="toggleFiltros" :filtros="filtrosOrcamento" />
    </v-card-title>
    <v-form autocomplete="off" lazy-validation>
      <v-container fluid grid-list-md v-show="filtrosAbertos">
        <metadados-container-layout
          v-if="metadadosOrcamento != null && carregouCampos"
          :metadados-entidade="metadadosOrcamento"
          :ordenacao-campos="ordenacaoCampos"
          :campos-formulario="camposFormulario"
          :explode-hierarquia="true"
          :formulario-filtros="true"
          :ignora-obrigatorios="true"
          :layout-class="layoutClass"
          :input-layout="inputLayout"
          :objeto="filtrosOrcamento"
          ref="container">
        </metadados-container-layout>
      </v-container>
    </v-form>
    <v-card-actions v-show="filtrosAbertos">
      <v-spacer></v-spacer>
      <v-btn @click="resetaFiltros"
        color="accent"
      >{{$t('label.limpar_filtros')}}</v-btn>
      <v-btn @click="aplicarFiltros"
        color="primary"
      >{{$t('label.filtrar')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex';
import MetadadosContainerLayout from '../../shared-components/metadados/MetadadosContainerLayout';
import { skipLoading } from '../../common/functions/loading';

import {
  copyObject,
} from '../../common/functions/helpers';

import OrcamentoListFiltrosCampos from './OrcamentoListFiltrosCampos';
import IconFiltro from '../../shared-components/filtros/IconFiltro';

export default {
  name: 'OrcamentoFiltros',
  mixins: [
    OrcamentoListFiltrosCampos,
  ],
  components: {
    MetadadosContainerLayout,
    IconFiltro,
  },
  computed: {
    ...mapGetters('metadados', [
      'getOrcamentoMetadado',
    ]),
    filtrosFixosClass() {
      return {
        'primary--text': this.filtrosFixados,
      };
    },
    carregouCampos() {
      return this.ordenacaoCampos.length > 0;
    },
  },
  data() {
    return {
      workspaceFiltrosResources: this.$api.workspaceFiltros(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),

      filtrosAbertos: false,
      filtrosFixados: false,

      metadadosOrcamento: null,

      ordenacaoCampos: [],

      layoutClass: { wrap: true },
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
      filtrosOrcamento: {},
      workspace: {},
      tiposDeInvestimento: null,
    };
  },
  methods: {
    carregarWorkspaceFiltros() {
      const entidade = 'orcamento';
      this.workspaceFiltrosResources.pesquisar({ entidade })
        .then((response) => {
          this.workspace = response.data || {};
          this.filtrosFixados = this.workspace.indAberto || false;
          this.filtrosAbertos = this.filtrosFixados;
          this.filtrosOrcamento = this.workspace.filtros || {};

          setTimeout(() => this.$refs.container.refresh());
          setTimeout(() => this.restaurarFiltros());
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    toggleFiltros() {
      this.filtrosAbertos = !this.filtrosAbertos;
    },
    fixaFiltros() {
      this.filtrosFixados = !this.filtrosFixados;
      this.workspace.indAberto = this.filtrosFixados;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    inserirWorkspace() {
      skipLoading();
      const entidade = 'orcamento';
      this.workspaceFiltrosResources.inserir({ entidade }, this.workspace)
        .then((response) => {
          this.workspace.id = response.data;
        }).catch((err) => {
          this.$error(this, err);
        });
    },
    atualizarWorkspace() {
      skipLoading();
      const entidade = 'orcamento';
      this.workspaceFiltrosResources.atualizar({ entidade }, this.workspace)
        .then()
        .catch((err) => {
          this.$error(this, err);
        });
    },
    resetaFiltros() {
      this.filtrosOrcamento = {};
      this.$refs.container.refresh();
      setTimeout(() => this.aplicarFiltros());
    },
    restaurarFiltros() {
      const parametros = {
        ...this.$refs.container.getValoresDependencias(),
        ...this.$refs.container.getValoresCamposPadrao(),
        ...this.$refs.container.getValoresCamposDinamicos(),
      };
      this.$emit('OrcamentoListFiltros__AplicaFiltros', parametros);
    },
    aplicarFiltros() {
      this.restaurarFiltros();
      this.salvarFiltrosWorkspace();
    },
    salvarFiltrosWorkspace() {
      this.workspace.filtros = this.filtrosOrcamento;
      if (!this.workspace.id) {
        this.inserirWorkspace();
      } else {
        this.atualizarWorkspace();
      }
    },
    buscarTiposDeInvestimento() {
      return this.tipoInvestimentoResources.listarAtivos()
        .then((res) => {
          this.tiposDeInvestimento = res.data.resposta;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    triggerTiposInvestimento() {
      this.triggerClick('orcamento-filtros-tipoinvestimento');
    },
    triggerClick(id) {
      document.getElementById(id).click();
    },
    habilitaCamposData() {
      const indicePeriodo = this.ordenacaoCampos.indexOf('periodo');
      const indiceReferencial = indicePeriodo + 1;
      if (indicePeriodo >= 0) {
        this.ordenacaoCampos.splice(indiceReferencial, 0, 'data_inicio');
        this.ordenacaoCampos.splice(indiceReferencial + 1, 0, 'data_fim');
      }
    },
    carregaCamposFiltros() {
      this.configuracaoResource.buscarCamposFiltro()
        .then((res) => {
          this.ordenacaoCampos = res.data;
          this.habilitaCamposData();

          setTimeout(() => this.carregarWorkspaceFiltros());
        }).catch((err) => {
          this.$error(this, err);
        });
    },
  },
  mounted() {
    const metadados = this.getOrcamentoMetadado;
    this.metadadosOrcamento = copyObject(metadados);
    this.carregaCamposFiltros();
  },
};
</script>
