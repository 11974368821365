<template>
  <v-card>
    <v-card-title>
      <div class="title">{{ $tc('title.orcamento', 2) }}</div>
    </v-card-title>
    <v-container v-if="carregando" fluid class="py-0">
      <v-row>
        <v-progress-linear
          indeterminate
          color="primary"/>
      </v-row>
    </v-container>
    <v-expansion-panels
        accordion
        focusable
        v-if="!iniciandoPainel">
      <v-expansion-panel
          v-for="(tipoVerba, index) in tiposVerbas"
          :key="tipoVerba.id">
        <v-expansion-panel-header :id="`orc_tipoverba-${index}`">
          {{ tipoVerba.nome }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <orcamento-list-dados-tabela
            :tipo-verba="tipoVerba"
            :ordenacao="parametrizacaoOrcamento[tipoVerba.id]"
            :filtros="filtrosAplicados"
            :ref="`tabela-${tipoVerba.id}`"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>
<script>
import OrcamentoListDadosTabela from './OrcamentoListDadosTabela';
import {
  buscarTiposVerbaOrcamento,
} from '../../common/resources/orcamento-listagem';

export default {
  name: 'OrcamentoListDados',
  components: {
    OrcamentoListDadosTabela,
  },
  data() {
    return {
      orcamentoResources: this.$api.orcamentoListagem(this.$resource),
      configuracaoResource: this.$api.orcamentoConfiguracao(this.$resource),

      tiposVerbas: [],
      painel: null,
      iniciandoPainel: true,

      filtrosAplicados: {},
      parametrizacaoOrcamento: {},

      carregando: false,
    };
  },
  methods: {
    async carregaParametrosTiposVerba(tiposVerbas) {
      await Promise.all(tiposVerbas
        .map((tipo) => this.configuracaoResource
          .buscarCamposGrid({ idTipoVerba: tipo.id })
          .then((res) => {
            const campos = res.data;
            this.parametrizacaoOrcamento[tipo.id] = campos;
          })));
    },
    aplicaFiltros(filtros) {
      this.tiposVerbas = null;
      this.filtrosAplicados = { ...filtros };
      this.painel = null;

      this.carregando = true;
      buscarTiposVerbaOrcamento(this.filtrosAplicados, this.$resource)
        .then((res) => {
          const tiposVerbas = res.data;
          this.carregaParametrosTiposVerba(tiposVerbas)
            .then(() => {
              this.tiposVerbas = tiposVerbas;
              this.iniciandoPainel = false;
              this.$forceUpdate();
            });
        })
        .catch((err) => {
          this.$toast(err.data.error);
        })
        .finally(() => {
          this.carregando = false;
        });
    },
  },
};
</script>
